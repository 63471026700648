import React from 'react'

import '../../styles/metrics/text.css'

const testingText = () => (
  <svg id="testingText" x="0px" y="0px" viewBox="0 0 513.9 704.3">
    <line className="st0" x1="99.8" y1="504.8" x2="514.4" y2="504.8" />
    <line className="st1" x1="99.8" y1="337" x2="498.6" y2="337" />
    <line className="st2" x1="99.8" y1="198.9" x2="460.1" y2="198.9" />
    <line className="st2" x1="99.8" y1="146.7" x2="449.1" y2="146.7" />
    <line className="st2" x1="99.8" y1="172.9" x2="460.1" y2="172.9" />
    <rect x="99.8" y="87.3" className="st3" width="296.9" height="58.1" />
    <text transform="matrix(1 0 0 1 99.8192 95.8647)">
      <tspan x="0" y="0" className="st4 st5 st6">
        All symptomatic people, all asymptomatic{' '}
      </tspan>
      <tspan x="0" y="14" className="st4 st5 st6">
        high-risk individuals or contacts, and anyone{' '}
      </tspan>
      <tspan x="0" y="28" className="st4 st5 st6">
        who requests a test/any physician who orders{' '}
      </tspan>
      <tspan x="0" y="42" className="st4 st5 st6">
        a test can access testing{' '}
      </tspan>
    </text>
    <rect x="99.8" y="155" className="st3" width="296.9" height="17.5" />
    <text transform="matrix(1 0 0 1 99.8192 163.562)" className="st4 st5 st6">
      Tests are readily available for all essential personnel
    </text>
    <rect x="99.8" y="181.3" className="st3" width="296.9" height="18.5" />
    <text transform="matrix(1 0 0 1 99.8192 189.8011)" className="st4 st5 st6">
      Majority of test results are returned within 24 hours
    </text>
    <rect x="99.8" y="206" className="st3" width="296.9" height="18.5" />
    <text transform="matrix(1 0 0 1 99.8192 214.4996)" className="st4 st5 st6">
      Sufficient number of tests per day
    </text>
    <line className="st1" x1="99.8" y1="281" x2="484.5" y2="281" />
    <line className="st1" x1="99.8" y1="309.2" x2="498.6" y2="309.2" />
    <rect x="99.8" y="249.4" className="st3" width="301.7" height="27.1" />
    <text transform="matrix(1 0 0 1 99.82 257.9073)">
      <tspan x="0" y="0" className="st4 st5 st6">
        All symptomatic people, all asymptomatic high-risk
      </tspan>
      <tspan x="0" y="14" className="st4 st5 st6">
        individuals or contacts can access testing{' '}
      </tspan>
    </text>
    <rect x="99.8" y="290.4" className="st3" width="296.9" height="17.5" />
    <text transform="matrix(1 0 0 1 99.8192 298.9397)" className="st4 st5 st6">
      Tests are readily available for all essential personnel
    </text>
    <rect x="99.8" y="346" className="st3" width="296.9" height="28.9" />
    <text transform="matrix(1 0 0 1 99.8202 354.4948)">
      <tspan x="0" y="0" className="st4 st5 st6">
        Increasing number of tests per day;
      </tspan>
      <tspan x="0" y="14" className="st4 st5 st6">
        nearing sufficiency
      </tspan>
    </text>
    <rect x="99.8" y="318.6" className="st3" width="296.9" height="18.5" />
    <text transform="matrix(1 0 0 1 99.8192 327.0942)" className="st4 st5 st6">
      Majority of test results are returned within 24 hours
    </text>
    <line className="st0" x1="99.8" y1="442" x2="510.1" y2="442" />
    <line className="st0" x1="99.8" y1="474" x2="514.4" y2="474" />
    <rect x="99.8" y="407.7" className="st3" width="301.7" height="27.1" />
    <text transform="matrix(1 0 0 1 99.82 416.1855)">
      <tspan x="0" y="0" className="st4 st5 st6">
        All symptomatic people, all asymptomatic high-risk
      </tspan>
      <tspan x="0" y="14" className="st4 st5 st6">
        individuals or contacts can access testing{' '}
      </tspan>
    </text>
    <rect x="99.8" y="453.6" className="st3" width="296.9" height="17.5" />
    <text transform="matrix(1 0 0 1 99.8192 462.1106)" className="st4 st5 st6">
      Tests are readily available for all essential personnel
    </text>
    <rect x="99.8" y="484.3" className="st3" width="296.9" height="18.5" />
    <text transform="matrix(1 0 0 1 99.8192 492.8372)" className="st4 st5 st6">
      Majority of test results are returned within 48 hours
    </text>
    <rect x="99.8" y="514.5" className="st3" width="296.9" height="18.5" />
    <text transform="matrix(1 0 0 1 99.8192 523.0452)" className="st4 st5 st6">
      Increasing number of tests per day
    </text>
    <g>
      <rect x="19.2" y="18.1" className="st3" width="118.1" height="37.2" />
      <text transform="matrix(1 0 0 1 33.5648 26.905)">
        <tspan x="0" y="0" className="st7 st8 st6">
          Faster &amp; Widely{' '}
        </tspan>
        <tspan x="18.7" y="14" className="st7 st8 st6">
          Available
        </tspan>
      </text>
    </g>
    <g>
      <rect x="19.2" y="576.6" className="st3" width="118.1" height="37.2" />
      <text transform="matrix(1 0 0 1 32.0228 585.3801)">
        <tspan x="0" y="0" className="st7 st8 st6">
          Slower and Less{' '}
        </tspan>
        <tspan x="20.2" y="14" className="st7 st8 st6">
          Available
        </tspan>
      </text>
    </g>
  </svg>
)

export default testingText
